.separator {
    width: 100%;
    height: 1px;
    background-color: gainsboro;
    margin: 20px 0px;
}

.spacer {
    width: 100%;
    height: 40px;
}