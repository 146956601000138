body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
}

.clickable {
  cursor: pointer;
}